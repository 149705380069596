import React, { useState, Suspense, lazy } from 'react'
import { ContactFormV2 } from '@ashleynexvelsolutions/contact-form-v2'
import { Hero } from '@ashleynexvelsolutions/hero'
import { OurStory } from '@ashleynexvelsolutions/our-story'
import { Portal } from '@ashleynexvelsolutions/portal'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { ServiceBlocks } from '@ashleynexvelsolutions/service-blocks'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import {
  SeoFunction,
  PageSpecificSchemaFunction,
  HomeHeroFunction,
  SdDifferenceFunction,
  OurStoryFunction,
  IndustriesWeServeFunction,
  MembershipsFunction,
  TestimonialsFunction,
  HelpFunction,
  OurServicesFunction,
  MeetTeamFunction,
  LatestBlogsFunction,
  LetsChatFunction,
} from '../DataImports'

const OurServices = lazy(() =>
  import('@ashleynexvelsolutions/our-services').then(module => ({
    default: module.OurServices,
  })),
)
const IndustriesWeServe = lazy(() =>
  import('@ashleynexvelsolutions/industries-we-serve').then(module => ({
    default: module.IndustriesWeServe,
  })),
)
const Memberships = lazy(() =>
  import('@ashleynexvelsolutions/memberships').then(module => ({
    default: module.Memberships,
  })),
)
const Testimonials = lazy(() =>
  import('@ashleynexvelsolutions/testimonials').then(module => ({
    default: module.Testimonials,
  })),
)
const Help = lazy(() =>
  import('@ashleynexvelsolutions/help').then(module => ({
    default: module.Help,
  })),
)
const MeetTeam = lazy(() =>
  import('@ashleynexvelsolutions/meet-team').then(module => ({
    default: module.MeetTeam,
  })),
)
const LatestBlogs = lazy(() =>
  import('@ashleynexvelsolutions/latest-blogs').then(module => ({
    default: module.LatestBlogs,
  })),
)
const SectionLabel = lazy(() =>
  import('@ashleynexvelsolutions/section-label').then(module => ({
    default: module.SectionLabel,
  })),
)
const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)
const SDdifference = lazy(() =>
  import('@ashleynexvelsolutions/sd-difference').then(module => ({
    default: module.SDdifference,
  })),
)

const Home = ({ data }) => {
  const [formModal, setFormModal] = useState(false)

  return (
    <Layout>
      <div className="relative bg-white">
        <Hero handleMainBtn={() => setFormModal(true)} {...HomeHeroFunction()} />
        {HomeHeroFunction().variant === 'primary' && (
          <div className="lg:-mt-6 relative z-10 lg:pl-[4.5rem]">
            <ServiceBlocks servicesData={HomeHeroFunction().servicesData} />
          </div>
        )}
      </div>
      <div className="scroll-container">
        <div id="our-stories">
          <OurStory {...OurStoryFunction()} />
        </div>
      </div>
      <Suspense fallback="loading">
        <OurServices handleMainBtn={() => setFormModal(true)} {...OurServicesFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <IndustriesWeServe
          handleMainBtn={() => setFormModal(true)}
          {...IndustriesWeServeFunction()}
        />
      </Suspense>
      <Suspense fallback="loading">
        <SDdifference {...SdDifferenceFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <Memberships {...MembershipsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <Testimonials {...TestimonialsFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <Help handleMainBtn={() => setFormModal(true)} {...HelpFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <MeetTeam {...MeetTeamFunction()} />
      </Suspense>
      <Suspense fallback="loading">
        <LatestBlogs {...LatestBlogsFunction()} />
      </Suspense>
      <section className="flex w-full px-3 justify-center items-center bg-blue-dark relative">
        <Suspense fallback="loading">
          <SectionLabel
            label={LetsChatFunction().sectionLabel.label}
            labelPos="pt-12"
            bgCol={LetsChatFunction().sectionLabel.bgColor}
            txtCol={LetsChatFunction().sectionLabel.textColor}
            customStyle="z-[1] hidden lg:flex"
          />
        </Suspense>
        <img
          src={LetsChatFunction().bgImage}
          srcSet={LetsChatFunction().bgImageSrcSet}
          alt={LetsChatFunction().bgImageAlt}
          className="absolute top-0 h-[600px] xl:h-[800px] xxl:h-[980px] object-cover"
          style={{
            filter: 'grayscale(1)',
          }}
        />
        <div
          className="absolute top-0 w-full h-[600px] xl:h-[800px] xxl:h-[980px]"
          style={{
            background: 'rgba(0, 0, 0, 0.53)',
          }}
        />
        <div className="absolute w-full top-[600px] xl:top-[660px] xxl:top-[780px] h-[500px] lg:h-[300px] bg-blue-dark z-[2]" />
        <div className="max-w-6xl mt-[60px] lg:mt-[80px] xxl:mt-[96px] z-[3]">
          <ContactFormV2 {...LetsChatFunction().form} />
        </div>
      </section>

      <Portal isOpen={formModal} onClose={() => setFormModal(false)}>
        <ContactFormV2
          type="modal"
          title="Let's Chat!"
          subTitle="Get a Free Consultation"
          borderColor="border-green-light"
          bgColor="bg-white"
          subTitleColor="text-green-lighter"
          subtitleBorderColor="bg-green-lighter"
          inputColor="text-[#363636]"
          inputOrderNumberColor="before:text-grey-lightest"
          inputBorderColor="border-grey-dark"
          selectBorderColor="border-gray-300"
          selectTextColor="text-gray-300"
          buttonBgColor="bg-green-light"
        />
      </Portal>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}
export const query = graphql`
  {
    wpPage(title: { eq: "Home" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphUrl
        opengraphSiteName
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
        canonical
        twitterTitle
        twitterDescription
      }
      nexvelschemapagesposts {
        videos {
          title
          url
          description
          thumbnailImage {
            localFile {
              publicURL
            }
          }
          uploadDate
        }
        questionsAndAnswers {
          question
          answer
        }
        maps {
          mapUrl
        }
        digitaldocuments {
          title
        }
        images {
          image {
            localFile {
              publicURL
            }
            date(formatString: "LL")
            description
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Home
export const Head = ({ data }) => <Seo2 {...SeoFunction(data)} />
